<template>
  <div class="d-flex group-items-container auto-group" :style="{ bottom: groupItemsList && groupItemsList.length !== 0 ? '95px' : '64px' }">
    <div class="group-item" @click="handleClickGroup(group)" @mouseover="hoveredGroup = group" @mouseleave="hoveredGroup = null" v-for="group in groupDataKeys" :key="group" :class="{ 'is-active': selectedAutoGroup && selectedAutoGroup === group }">
      <div class="group-placeholder"></div>
      <div class="group-items-count b-group">{{ groupData[group].length }}</div>
      <b-avatar :variant="selectedAutoGroup && selectedAutoGroup === group ? 'primary gradient-primary' : 'light-primary'">
        <span>{{ group }}</span>
      </b-avatar>
      <div @click.stop="() => {}" v-if="hoveredGroup == group" class="group-popover custom-scrollbar is-active" :class="{ 'is-active': hoveredGroup == group }" :style="{ bottom: '33px' }">
        <div class="popover-arrow"></div>
        <div class="popover-body">
          <div class="d-flex justify-content-between align-items-center mb-1">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar size="32" :variant="`light-primary`" :title="`Group ${group}`">
                  <span style="font-size: 12px">{{ group }}</span>
                </b-avatar>
              </template>
              <small class="text-muted">{{ groupData[group].length + ' ' + $t('locations')}}</small>
            </b-media>
          </div>
          <b-button v-for="item of groupData[group]" :key="item" pill size="sm" class="popover-item" v-ripple.400="'rgba(57, 123, 255, 0.15)'" variant="outline-primary"> {{ getFileName(item) }} </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const _ = require('lodash');
import Ripple from 'vue-ripple-directive';
import { splitByLastIndex } from '@/utilities/StringUtility.js';

export default {
  directives: { Ripple },
  props: {
    groupData: {
      type: Object,
      default: null,
    },
    autoGroup: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      hoveredGroup: null,
      selectedAutoGroup: this.autoGroup ? this.autoGroup : null,
    };
  },
  computed: {
    datasources() {
      return this.$store.state.datasource.datasources.filter((d) => d.type === 'timeseries');
    },
    groupItemsList() {
      return this.$store.state.groupItems.groupItemsList;
    },
    groupDataKeys() {
      if (!this.groupData) return [];
      return Object.keys(this.groupData);
    },
  },
  watch: {
    autoGroup() {
      this.selectedAutoGroup = this.autoGroup;
    },
  },
  methods: {
    handleClickGroup(group) {
      if (this.selectedAutoGroup !== group) this.selectedAutoGroup = group;
      else this.selectedAutoGroup = null;
      this.$store.commit('tabs/UPDATE_AUTOGROUP', { routeName: this.$route.name, autoGroup: this.selectedAutoGroup });
    },
    getFileName(item) {
      const [locationItems, datasourceId] = splitByLastIndex(item, '|');
      let datasource = this.datasources.find((ds) => ds.id === datasourceId);
      return `${locationItems.replace('*', '-')}|${datasource.name}`;
    },
  },
};
</script>

<style>
.auto-group .group-item .group-popover {
  bottom: 97px;
}
.auto-group .group-item .b-avatar {
  width: 32px;
  height: 32px;
}
.group-items-container .group-item {
  z-index: 2;
}
</style>
<style scoped>
.auto-group {
  bottom: 64px;
  user-select: none;
}
</style>
